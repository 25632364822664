.container{
  position: relative;
  width: calc(100% - 140px);
  height: 770px;
  background: linear-gradient(94deg, #F2F2F2 0%, #DBDBDB 99%);
  display: flex;
  justify-content: space-between;
  padding: 0 70px;
  //margin-top: 100px;
}

.left{
  text-align: start;
}
.right{
  width: 863px;
  height: 701px;
  position: absolute;
  right: 22px;
  bottom: 0px;
}
.banner-title{
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  line-height: 60px;
  margin-top: 250px;
}