.about-box{
  width: 100%;
  height: 843px;
  background: #F6F6F6;
  position: relative;
  margin-top: 70px;
  .content-box{
    width: 691px;
    line-height: 60px;
    text-align: left;
    margin-right: 10%;
    p{
      text-indent: 60px;
    }
  }
  .bg-img{
    width: 827px;
    height: 761px;
    margin-top: 82px;
  }
}