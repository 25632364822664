.list{
  .bg-img{
    width: 600px;
    height: 523px;
  }
  .icon{
    width: 68px;
    height: 68px;
  }

}