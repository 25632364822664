.App {
  width: 100%;
  text-align: center;
  min-width: 1440px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  width: calc(100% - 140px);
  background-color: white;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: #333333;
  padding: 0 70px;
  //position: fixed;
  //left: 0;
  //top: 0;
  z-index: 99999;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@for $i from 1 through 35 {
  .m-#{$i} { margin: ($i)+px; }
  .mt-#{$i} { margin-top: ($i)+px; }
  .mb-#{$i} { margin-bottom: ($i)+px; }
  .ml-#{$i} { margin-left: ($i)+px; }
  .mr-#{$i} { margin-right: ($i)+px; }

  .p-#{$i} { padding: ($i)+px; }
  .pt-#{$i} { padding-top: ($i)+px; }
  .pb-#{$i} { padding-bottom: ($i)+px; }
  .pl-#{$i} { padding-left: ($i)+px; }
  .pr-#{$i} { padding-right: ($i)+px; }
  .fz-#{$i} { font-size: $i + px; }
}
.ml-55{
  margin-left: 55px;
}
.fw-600{
  font-weight: 600;
}
.cl-333{
  color: #333333;
}
.cl-666{
  color: #666666;
}
.cl-888{
  color: #888888;
}
.flexHC{
  display: flex;
  align-items: center;
}
.flexVC{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.flexHC2{
  display: flex;
  align-items: center;
  justify-content: center;
}
.flexVC2{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.w-100{
  width: 100%;
}

