.bottom-box{
  width: 100%;
  height: 300px;
  padding-top: 85px;
  background: #1D1D1D;
  color: #FFFFFF;
  .line{
    width: 2px;
    height: 88px;
    background: #B8B8B8;
    margin: 0 120px;
  }
  img{
    width: 25px;
    height: 25px;
  }
}

