.item-top{
  width: 220px;
  height: 180px;
  background: #F2F2F2;
  box-shadow: 3px 3px 13px 0px rgba(22, 23, 23, 0.18);
  border-radius: 16px 16px 0px 0px;
  img{
    width: 168px;
    height: 107px;
  }
}
.item-bottom{
  width: 150px;
  height: 168px;
  padding: 0 35px;
  padding-top: 40px;
  background: #FFFFFF;
  box-shadow: 3px 3px 13px 0px rgba(22, 23, 23, 0.18);
  border-radius: 0px 0px 16px 16px;
  line-height: 48px;
  text-align: start;
}